import { Link } from 'react-router-dom';
import '../css/font-awesome.min.css';
// import '../css/css/brands.min.css';

const Footer = () => {
  return (
    <div className='footer' id='footer'>
      <div className='container'>
        <div className='footer__content'>
          <div className='footer__inner'>
            <div className='footer__logo'>
              <Link to='/'>
                <h2>
                  <strong>3D</strong>ArtSome
                </h2>
              </Link>
            </div>
            <div className='footer__col'>
              <div className='footer__col-title'>Contacts</div>
              <div className='footer__info'>
                <div className='footer__info-title'>Address:</div>
                <a
                  href='https://goo.gl/maps/9nt5jmzUBNc5kzp39'
                  target='_blank'
                  rel='noreferrer'
                >
                  Іrpin, Kyiv region, 08200 Sadova st., 28
                </a>
              </div>
              <div className='footer__info footer__info-phone'>
                <div className='footer__info-title'>Phone number:</div>
                <a href='tel:+380667534914'>+38-066-753-49-14</a>
                <br />
                <a href='tel:+380935337242'>+38-093-533-72-42</a>
              </div>
              {/* <a className="footer__info footer__info-phone" href="tel:0935337242">
                <div className="footer__info-title">Телефон:</div>
                +38-093-533-72-42
              </a> */}
              <a
                className='footer__info footer__info-mail'
                href='mailto:3dartsome@gmail.com'
              >
                <div className='footer__info-title'>E-mail:</div>
                3dartsome@gmail.com
              </a>
            </div>
            {/* <div className="footer__col footer__col-menu">
              <div className="footer__col-title">Услуги</div>
              <ul className="footer__col-list">
                <li><a href="/">Landing page</a></li>
                <li>
                  <a href="/">Корпоративный сайт</a>
                </li>
                <li>
                  <a href="/">Интернет магазин</a>
                </li>
                <li><a href="/">Бизнес сайт</a></li>
                <li><a href="/">Сайт визитка</a></li>
                <li><a href="/">Дизайн сайта</a></li>
                <li><a href="/">Разработка сайта</a></li>
                <li><a href="/">Редизайн сайта</a></li>
              </ul>
            </div> */}
            <div className='footer__col'>
              <div className='footer__col-title'>Social network</div>
              <ul className='footer__social-link'>
                {/* <li>
                  <a href='/' className='fa fa-facebook'>
                    {' '}
                  </a>
                </li> */}
                <li>
                  <a
                    href='https://www.instagram.com/3dartsome/'
                    className='fa fa-instagram'
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    {' '}
                  </a>
                </li>
                <li>
                  <a
                    href='https://t.me/DimaHrusha'
                    className='fa fa-telegram'
                    style={{
                      content: 'url(../telegram-plane.svg)',
                      //   width: '200px',
                      //   height: '200px',
                    }}
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    {' '}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='footer__copy'>
        <div className='container'>
          <div className='footer__copy-text'>by 3DArtsome.com</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
