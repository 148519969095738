const WhyUs = () => {
  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        entry.target.classList.add("square-animation");
        return; // if we added the class, exit the function
      } else {
        entry.target.classList.remove("square-animation");
        return;
      }

      // We're not intersecting, so remove the class!
    });
  });

  let whyList = document.getElementsByClassName("about__us-row");
  for (let i = 0; i < whyList.length; i++) {
    const el = whyList[i];
    observer.observe(el);
  }

  console.log(whyList);

  return (
    <section className="about__us">
      <div className="container">
        <div className="about__us-content">
          <div className="about__us-inner">
            <div className="about__us-title">
              <h2>Why us?</h2>
            </div>
          </div>
          <div className="about__us-wrap">
            <div className="about__us-row">
              <div className="number">01</div>
              <div className="text">
                <h3>Transparent working conditions.</h3>
                <p>Contract with guarantees and obligations.</p>
              </div>
            </div>
            <div className="about__us-row">
              <div className="number">02</div>
              <div className="text">
                <h3>Flexible payment system.</h3>
                <p>70/30, 60/40, 50/50 за этап.</p>
              </div>
            </div>
            <div className="about__us-row">
              <div className="number">03</div>
              <div className="text">
                <h3>Constant feedback.</h3>
                <p>In communication channels convenient for you.</p>
              </div>
            </div>
            <div className="about__us-row">
              <div className="number">04</div>
              <div className="text">
                <h3>Reporting on the stages of work.</h3>
                <p>Watch the result in real time.</p>
              </div>
            </div>
            <div className="about__us-row">
              <div className="number">05</div>
              <div className="text">
                <h3>Training to work with the site, tech. support.</h3>
                <p>Consultations and recommendations after the launch.</p>
              </div>
            </div>
            <div className="about__us-row">
              <div className="number">06</div>
              <div className="text">
                <h3>Individual design and development.</h3>
                <p>
                  Clean and optimized code, responsive design for your target
                  audience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
