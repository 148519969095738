import { useState } from 'react';

const Feedback = () => {
  const feedback = require('../images/content/icon/feedback.jpg').default;

  const [name, setName] = useState('');
  const [mail, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const sendEmail = (e) => {
    let xhr = new XMLHttpRequest();

    // xhr.addEventListener('load', () => {
    //   console.log(xhr.responseText);
    // });

    let host = window.location.hostname;
    let button = document.getElementById('feedback-button');

    xhr.open(
      'GET',
      'https://' +
        host +
        '/sendemail.php?name=' +
        name +
        '&email=' +
        mail +
        '&phone=' +
        phone +
        '&message=' +
        (message ? message : 'null'),
      true
    );

    // xhr.onprogress = () => {
    button.innerHTML = 'Sending...';
    button.classList.remove('sent');
    button.classList.remove('error-sending');
    button.classList.add('sending');
    // }

    xhr.onload = function () {
      let response = xhr.responseText;
      if (this.status === 200 && response === 'ok') {
        button.classList.remove('sending');
        button.classList.remove('error-sending');
        button.classList.add('sent');
        button.innerHTML = 'Sent';
      } else {
        button.innerHTML = 'Error';
        button.classList.remove('sent');
        button.classList.remove('sending');
        button.classList.add('error-sending');
        console.log('Error', xhr.statusText);
      }
    };
    xhr.send();
    e.preventDefault();
  };

  return (
    <section className='feedback' id='contacts'>
      <div className='feedback__title'>
        <h2>Write to us..</h2>
        <img src={feedback} alt='' />
      </div>
      <div className='feedback__box'>
        <div className='page'>
          <form className='form' onSubmit={sendEmail}>
            <div className='form-group'>
              <label className='field-group'>
                <span className='field-label'>
                  Name <span style={{ color: 'red' }}>*</span>
                </span>
                <input
                  type='text'
                  name='name'
                  className='field'
                  placeholder=''
                  required
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  value={name}
                />
              </label>
            </div>
            <div className='form-group'>
              <label className='field-group'>
                <span className='field-label'>
                  Phone number <span style={{ color: 'red' }}>*</span>
                </span>
                <input
                  type='tel'
                  name='tel'
                  className='field'
                  placeholder=''
                  required
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  value={phone}
                />
              </label>
            </div>
            <div className='form-group'>
              <label className='field-group'>
                <span className='field-label'>Email</span>
                <input
                  type='email'
                  name='email'
                  className='field'
                  placeholder=''
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={mail}
                />
              </label>
            </div>
            <div className='form-group'>
              <label className='field-group'>
                <span className='field-label'>Message</span>
                <textarea
                  type='text'
                  name='message'
                  className='field textarea'
                  placeholder=''
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                  value={message}
                ></textarea>
              </label>
            </div>
            <div className='form-group form-actions'>
              <button
                className='button button-submit'
                type='submit'
                id='feedback-button'
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Feedback;
