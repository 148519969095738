import { useLocation } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
// import stars from './stars.js';

// const usePathname = () => {
//   const location = useLocation();
//   return location.pathname;
// }

const Navbar = () => {
  const location = useLocation();

  const loadScript = (src) => {
    const tag = document.createElement("script");
    tag.async = false;
    tag.src = src;
    document.getElementsByTagName("body")[0].appendChild(tag);
  };

  loadScript("/stars.js");
  return (
    <header>
      <div className="colum__header">
        <div className="header__logo">
          <Link to="/">
            <h2>
              <strong data-text="3D">3D</strong>
              <p>ArtSome</p>
            </h2>
          </Link>
        </div>

        <div className="header__menu">
          <div className="header__menu-container">
            <nav>
              <div className="header__nav-menu">
                <ul>
                  <li>
                    <Link to="/#about">About Us</Link>
                    <ul>
                      {/* <li><Link to="#">Landing page</Link></li>
                        <li><Link to="#">Корпоративный сайт</Link></li>
                        <li><Link to="#">Интернет магазин</Link></li>
                        <li><Link to="#">Бизнес сайт</Link></li>
                        <li><Link to="#">Сайт визитка</Link></li> */}
                    </ul>
                  </li>
                  <li>
                    <Link to="/#services">Services</Link>
                  </li>
                  <li>
                    <Link to="/#footer">Contacts</Link>
                    <ul>
                      {/* <li><Link to="#">2D/3D моушен графика</Link></li> */
                      /* <li><Link to="#">Эксплейнеры</Link></li>
                        <li><Link to="#">Инфографика</Link></li> */}
                    </ul>
                  </li>
                  <li>
                    <Link to="/#cases">Cases</Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>

        <div className="header__text">
          <a href="tel:380667534914">+38 (066) 753 49 14</a>
          <a href="mailto:3dartsome@gmail.com">3dartsome@gmail.com</a>
        </div>
      </div>

      {/* {console.log(pathname)} */}
      {location.pathname === "/" && (
        <>
          <div className="header__top">
            <div className="header__title animate__animated animate__fadeIn animate__delay-1s">
              <h1>We create websites for your business</h1>
            </div>
          </div>
          <canvas id="stars"></canvas>
        </>
      )}
    </header>
  );
};

export default Navbar;
