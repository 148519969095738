const About = () => {
  return (
    <section className="about_us" id="about">
      <div className="container">
        <div className="about_us-inner">
          <div className="about_us-inner_title">
            <h2>About us</h2>
          </div>
          <div className="about_us-text">
            <ul>
              <li>
                We help companies present products and services effectively.
              </li>
              <li>
                We help companies present products and services effectively.
              </li>
              <li>We create modern websites.</li>
              <li>
                We make user-friendly interfaces for applications and systems.
              </li>
              <li>
                We develop websites that demonstrate high quality products and
                sell more and faster.
              </li>
              {/* <li>Интегрируем  для локальных и международных клиентов.</li> */}
              {/* <li> Мы — команда узкопрофильных специалистов высшего класса, которые дополняют друг друга для лучшей реализации проекта.</li> */}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
