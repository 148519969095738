import { Fragment } from 'react';

const Cases = () => {
  let portf = [];
  let info = [];
  let cases = [];
  const quantity = 6;
  for (let i = 0; i < quantity; i++) {
    portf[i] = require(`../portfolio/${i + 1}/${i + 1}.jpg`).default;
    info[i] = require(`../portfolio/${i + 1}/${i + 1}.json`);
    cases[i] = (
      <div className='tile'>
        <img src={portf[i]} alt='wildtraveler' />
        <div className='text'>
          <h2>{info[i]['title']}</h2>
          <h3 className='animate-text'>{info[i]['header']}</h3>
          <p className='animate-text'>{info[i]['text']}</p>
          <a
            href={info[i]['link']}
            className='more-info animate-text'
            target='_blank'
            rel='noreferrer'
          >
            <button className='animate-text feedback-button button'>
              Visit
            </button>
          </a>
        </div>
      </div>
    );
  }

  return (
    <section className='portfolio' id='cases'>
      <div className='container'>
        <div className='portfolio__box'>
          <div className='portfolio__title'>Cases</div>
          <div className='portfolio__wrap'>
            <div className='wrap'>
              {cases.map((val, key) => {
                return <Fragment key={key}>{val}</Fragment>;
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cases;
