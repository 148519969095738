import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Navbar from './components/Navbar';
import Home from './components/Home';
import NotFound from './components/NotFound';
import Contacts from './components/Contacts';
import Footer from './components/Footer';

const App = () => {
  return (
    <Router>
      <>
        <div className="wrapper">
          <Navbar />
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/contacts">
                <Contacts />
              </Route>
              <Route path="*">
                <NotFound />
              </Route>
            </Switch>
        </div>
        <Footer />        
      </>
    </Router>
  );
}
 
export default App;