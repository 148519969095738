import About from "./About";
import Cases from "./Cases";
import Feedback from "./Feedback";
import Services from "./Services";
import WhyUs from "./WhyUs";

const Home = () => {  
  return (
    <div className="content">
      <About />
      <Services />
      <WhyUs />
      <Feedback />
      <Cases />
    </div>
  );
}
 
export default Home;