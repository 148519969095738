const Services = () => {
  const webdev =
    require("../images/content/icon/website_development.png").default;
  const mobile_app = require("../images/content/icon/mobile-app.png").default;
  const design = require("../images/content/icon/design.png").default;
  const drawing = require("../images/content/icon/drawing.png").default;
  const video = require("../images/content/icon/video.png").default;
  const infographic = require("../images/content/icon/infographic.png").default;
  return (
    <section className="services" id="services">
      <div className="container">
        <div className="services__inner-title">
          <h2>Services</h2>
        </div>
        <div className="services__items-box">
          <div className="services__items">
            <div className="services__items-box">
              <div className="services__items-inner">
                <img src={webdev} alt="" />
                <p>Website development</p>
              </div>
              <div className="services__items-inner">
                <img src={mobile_app} alt="" />
                <p>Mobile app</p>
              </div>
              <div className="services__items-inner">
                <img src={design} alt="" />
                <p>Design</p>
              </div>
              <div className="services__items-inner">
                <img src={drawing} alt="" />
                <p>Illustration</p>
              </div>
              <div className="services__items-inner">
                <img src={video} alt="" />
                <p>Motion design</p>
              </div>

              <div className="services__items-inner">
                <img src={infographic} alt="" />
                <p>Infographics</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
